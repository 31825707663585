<template>
  <div class="myaccount">
    <div class="title bg_fff Plf20 Ptp15 Pbm15 Mtp25 orange">我的钱包</div>
    <div class="fun Mtp20">
      <div class="balance bg_fff">
        <account-item>
          <div slot="num">
            {{ userInfo.balance == -1 ? 0 : userInfo.balance }}
            <i
              class="el-icon-refresh-right curPointer"
              @click="GetLoginUserInfoByToken()"
            ></i>
          </div>
          <div slot="text1">余额</div>
          <img slot="img" src="../../../../../assets/supplier/yue.png" />
        </account-item>
      </div>
      <div class="deposit bg_fff">
        <account-item>
          <!-- <div slot="num">0.00</div> -->
          <div slot="text1">充值记录<span class="Mlf10 F14 btn co_09f" @click="prepaidRecords()">查看</span></div>
          <div slot="text2" class="Mtp10">消费记录<span class="Mlf10 F14 btn co_09f" @click="recordsConsumption()">查看</span></div>
          <img slot="img" src="../../../../../assets/supplier/tixian.png" />
        </account-item>
      </div>
      <div class="account bg_fff">
        <account-item>
          <div slot="num" class="F14">{{ userInfo.withdrawalPwd ? '*******' : '暂未设置交易密码' }}</div>
          <div slot="text1" v-if="userInfo.withdrawalPwd">交易密码</div>
          <div slot="text2" class="F14 btn co_09f" @click="changePwd()">{{ userInfo.withdrawalPwd ? '修改' : '前去设置' }}</div>
          <img slot="img" src="../../../../../assets/supplier/user.png" />
        </account-item>
      </div>
    </div>
    <div class="title bg_fff Plf20 Ptp15 Pbm15 Mtp20">账户充值</div>
    <div class="withdrawal bg_fff Mtp20 Mbm100 Pbm80 het200 Plf20 Ptp30">
      <div class="withdrawalAmount">
        <el-form
          :model="withdrawalForm"
          label-width="100px"
          :rules="withdrawalFormRule"
        >
          <el-row :gutter="1">
            <el-col :span="7">
              <el-form-item label="充值金额" prop="money">
                <el-input-number
                  v-model="withdrawalForm.money"
                  @change="handleChange"
                  :min="1"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <el-button
                  :loading="loading"
                  class="wid100"
                  type="primary"
                  @click="rechargeBtn()"
                  >立即充值</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="上传凭证" prop="transferImage">
                <div>
                  <one-upload
                    ref="OneUpload"
                    moduleType="04"
                    :imgField="transferImage"
                    @imgUrl="getImgUrl"
                  ></one-upload>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- <div class="imgUp Ptp30 Pbm100">
        <div class="Mlf30 Mrt15 F14 co_7474">上传凭证</div>
        <div >
          <one-upload  moduleType="04" :imgField="transferImage"></one-upload>
        </div>
      </div> -->
    </div>
    <!-- 上传凭证 -->
  </div>
</template>
<script>
import accountItem from "@/components/supplier/accountItem.vue";

import OneUpload from "../../../../../components/upload/oneUpload.vue";
import { createUserTopUpRecord } from "@/api/user";
import { mapGetters } from "vuex";
export default {
  components: { accountItem, OneUpload },
  name: "MyAccount",
  data() {
    return {
      loading: false,
      flag: false,
      password: "",
      withdrawalForm: {
        money: 100,
      },
      withdrawalType: "3",
      transferImage: "",
      withdrawalFormRule: {
        money: [{ required: true, message: "请输入充值金额", trigger: "blur" }],
        transferImage: [
          { required: true, message: "请上传转账凭证", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {
    transferImage() {
      console.log("图片路径" + this.transferImage);
    },
  },
  mounted() {
    this.GetLoginUserInfoByToken();
  },
  methods: {
    GetLoginUserInfoByToken() {
      this.$store.dispatch("GetLoginUserInfoByToken");
    },
    handleChange(val) {
      this.withdrawalForm.money = val;
    },
    getImgUrl(val) {
      this.transferImage = val;
      console.log("val是" + val);
    },
    //跳转充值记录
    prepaidRecords() {
      this.$router.push("/purchaser/purchaserHome/Recharge");
    },
    //跳转消费记录
    recordsConsumption() {
      this.$router.push("/purchaser/purchaserHome/Expense");
    },
    // 立即充值
    rechargeBtn() {
      this.loading = true;
      createUserTopUpRecord(
        this.userInfo.id,
        this.withdrawalForm.money,
        this.transferImage
      )
        .then((res) => {
          this.loading = false;
          if (res.success) {
            this.GetLoginUserInfoByToken();
            this.clearData();
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 清空数据
    clearData() {
      this.withdrawalForm.money = 100;
      this.transferImage = "";
      this.$refs.OneUpload.clearData()
    },
    changePwd() {
      this.$router.push("/supplier/supplierHome/depositPwdChange");
    },
  },
};
</script>
<style scoped lang="scss">
.myaccount {
  .fun {
    display: flex;
    justify-content: space-between;
  }
}
</style>
